/*Colors*/
:root {
  --royal-blue: #242848;
  --royal-blue-2: #65677e;
  --royal-blue-light: #2F3367;
  --gold-opacity: #ebd1a5;
  --for-gold-light: #F4CA85;
  --for-gold-dark: #7E5936;
  --white-f7: #F7F7F7;
  --gray-strong: #7C868A;
  --gray-medium: #B8C0C2;
  --gray-light: #DFDFDF;
}


/*Customized font*/
@font-face {
  font-family: "Acumin-Variable-Concept";
  src: url("../fonts/Acumin-Variable-Concept.ttf");
  src: url("../fonts/Acumin-Variable-Concept.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/*   START GENERAL STYLES   */

/*Font*/
.font-acumin-variable {
  font-family: "Acumin-Variable-Concept",sans-serif,Arial, Helvetica !important;
}


body {
  background-color: white;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.facebook,
.twitter,
.instagram,
.youtube {
  transition: 200ms;
}

.facebook:hover,
.twitter:hover,
.instagram:hover,
.youtube:hover {
  transform: scale(1.20);
}


/* Change icons color from the side navbar for mobiles*/
.change-color-icons:hover {
  color: var(--for-gold-light) !important;
}

/* Cursor pointer */
.cursor-pointer:hover {
  cursor: pointer !important;
}
/* Cursor pointer */
.text-underline:hover {
  text-decoration: underline !important;
}

.no-border{
  border: none !important;
}


.text-no-decoration {
  text-decoration: none !important;
  list-style: none !important;
}

.text-hover-underline:hover{
  text-decoration: underline !important;
}

.text-hover-white{
  color: var(--white-f7) !important;
}

.text-hover-blue{
  color: var(--royal-blue) !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.text-overflow{
  overflow: hidden;
  text-overflow: ellipsis !important;
}

/*   END GENERAL STYLES   */


/* START HEADER STYLES */

/*Size of the logo BCV in header*/
.logo-header {
 /* width: 10.5rem !important;
  height: 3.5rem !important;*/
  transition: 300ms;
}

.logo-header-cehval{
  width: 3.5rem !important;
  height: 3.5rem !important;
  transition: 300ms;
}


/*Size of the logo CEHVAL in header*/
.logo-cehval-header {
 /* width: 4rem !important;
  height: 4rem !important;*/
  transition: 300ms;
}

#logo-cehval-header {
  transition: all 300ms;
}

#logo-cehval-header:hover {
  transform: scale(1.05);
}

/*Gold line in header*/
.border-bottom-gold {
  border-bottom: 1px solid var(--for-gold-light) !important;
}

/*MENU CEHVAL DROPDOWN*/
#cehval-menu {
  list-style: none !important;
  font-size: 17px !important;
  position: absolute !important;
  z-index: 1 !important;
  width: 300px !important;
  background-color: #f8f8f8;
  top: 122px;
}

/*Change text color when hover on dropdown menu options*/
.cehval-menu-option:hover .cehval-menu-option-text {
  text-shadow: 0.5px 0px var(--royal-blue) !important;
}

/*Show submenu when hover in one of the menu options*/
.cehval-menu-option:hover .submenu-dropdown {
  display: block !important;
}

/* START MENU FOR MOBILE DEVICES*/
/*Button to display menu in mobile devices*/
#btnSideNavbar {
  background-color: var(--gold-opacity) !important;
  transition: all 300ms;
}

#btnSideNavbar:focus {
  outline: none !important;
  border: 2px solid var(--for-gold-light) !important;
  box-shadow: none !important;
}

#btnSideNavbar:hover {
  transform: scale(0.9, 0.9);
}

/*Styles submenu*/
.submenu-dropdown {
  display: none;
  width: 800px !important;
  height: 700px;
  background-color: #ffffff;
  position: absolute !important;
  z-index: 2;
  top: 0px;
  left: 297px;
}

.submenu-option:hover {
  text-shadow: 0px 0.2px var(--gray-strong);
}

/*Tamaño para el logo dentro del sideNavbar*/
#logoSideNavbar {
 /* width: 15rem !important;
  height: 4rem !important;*/
 
}

.logoSideNavbarCehval {
  width: 5rem !important;
  height: 5rem !important;
}

/* END MENU FOR MOBILE DEVICES*/
/* END HEADER STYLES */


/* START BANNER STYLES HOMEPAGE */
/*background image banner*/
#banner {
  height: 690px !important;
  max-width: 100%;
  background-image: url(../assets/logosCehval/dinero.jpg) !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-banner {
  opacity: 0.9 !important;
  padding: 10px;
  background-color: var(--royal-blue);
}

/*Hover para boton de solicitar registro*/
.button-hover:hover {
  background-color: white !important;
  color: var(--royal-blue) !important;
}

/*Hover para boton del modal*/
.buttonHover:hover {
  background-color: var(--royal-blue) !important;
  color: var(--white-f7) !important;
}

/*END BANNER STYLES HOMEPAGE*/



/*START STYLES FOR COMPONENT BANNERTITULO*/
/*banner de fondo*/
.bannerTitulo {
  /*background-image: url("../assets/banner/banner.jpg");*/
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0px -200px;
  height: 7rem;
}

.textoBannerTitulo {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--royal-blue);
  text-align: center;
  font-family: "Acumin-Variable-Concept",sans-serif,Arial, Helvetica;
}

@media (max-width: 668px) {
  .textoBannerTitulo {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 466px) {
  .textoBannerTitulo {
    font-size: 1.3rem !important;
  }
}


/*END STYLES COMPONENT FOR BANNERTITULO*/

.imageCard{
  height: 23rem;
  width: 100%;
}


/*START STYLES STEPPER COMPONENT AND DEPOSITANTE DIRECTO*/
/* clase para steper*/
.steper {
  width: 2rem;
}

.padding-l-1rem {
  padding-left: 1.5rem !important;
}


.contenedorBotonesStteper {
  display: flex;
  justify-content: space-between;

}

.contenedorFormularioDepositanteDirecto card {
  /*background-color: var(--gray-light);*/
  color: var(--royal-blue);
  padding: 10px;
  border-radius: 10px;
}

.textoTituloFormulario {
  font-size: 20px;
  font-family: "Acumin-Variable-Concept",sans-serif,Arial, Helvetica;
}

.textoFormularioDepositantedirecto {
  font-size: 12px;
  font-family: "Acumin-Variable-Concept",sans-serif,Arial, Helvetica;
}

/*input del formulario de depositante*/
#contenedorsteper input {
  /*font-size: 12px;*/
  font-family: "Acumin-Variable-Concept",sans-serif,Arial, Helvetica;

}

#contenedorsteper select {
  /* font-size: 12px;*/
  font-family: "Acumin-Variable-Concept",sans-serif,Arial, Helvetica;
}

#contenedorsteper textarea {
  /* font-size: 12px;*/
  font-family: "Acumin-Variable-Concept",sans-serif,Arial, Helvetica;
}

#stepperComponent {
  width: 100%;
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  font-size: 10px !important;
}

/*Cursor pointer for circles in steppers*/
#stepperComponent div div div div:first-child:hover {
  cursor: pointer !important;
}

#stepperComponent div div > div:nth-child(2):hover {
  cursor: pointer !important;
}

#stepperComponent div div > div a:hover {
  cursor: pointer !important;
}

/*END STYLES STEPPER COMPONENT AND DEPOSITANTE DIRECTO*/


/*START STYLES FOR MAIN CONTENT*/
/*Altura contenedor de contenido principal archivo inicioCehval.js etiqueta main*/
.main-min-height {
  min-height: 37.2rem !important;
}

.min-height-40{
  min-height: 40rem;
}

.main-container {
  height: 973px !important;
}

.container-max-height {
  max-height: 1000px !important;
}

.container-min-height {
  min-height: 40rem;
}

/*Scroll del contenido principal archivo inicioCehval.js etiqueta main*/
.main-content {
  overflow-x: hidden;
  overflow-y: scroll !important;
}

/*END STYLES FOR MAIN CONTENT*/

/* START FONT SIZES*/
.fs-xx-small {
  font-size: 12px !important;
}

.fs-x-small {
  font-size: 13px !important;
}

.fs-15 {
  font-size: 15px;
}

.fs-medium {
  font-size: 18px !important;
}

.fs-social-icons {
  font-size: 0.75rem;
}

.text-size-12px {
  font-size: 12px;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20-r{
  font-size: 20px !important;
}

@media (max-width: 668px) {
  .fs-20-r {
    font-size: 18px !important;
  }
}

@media (max-width: 466px) {
  .fs-20-r {
    font-size: 15px !important;
  }
}

.fs-flex{
  font-size: 15px !important;
}

@media (max-width: 668px) {
  .fs-flex {
    font-size: 13px !important;
  }
}

@media (max-width: 466px) {
  .fs-flex{
    font-size: 12px !important;
  }
}

/* END FONT SIZES*/


.mb-user-icon {
  margin-bottom: 18px !important;
}

/*START CLASES PARA INPUTS Y LABELS*/
.row input{
  font-size:large;
}
.row select{
  font-size:large;
}

.row label{
  font-size: medium;
}

.emailHelp{
  margin-left: .6em;
}


/*START TEXT COLORS*/
.text-color-gray {
  color: #858585 !important;
}

.text-royal-blue {
  color: var(--royal-blue) !important;
}

.text-gold {
  color: var(--gold-opacity) !important;
}

.text-gold-light {
  color: var(--for-gold-light) !important;
}

.text-gray {
  color: var(--gray-strong) !important;
}

.text-f7 {
  color: var(--white-f7) !important;
}

.royal-blue-light {
  color: var(--royal-blue-light) !important;
}

.text-red {
  color: #E6222E !important;
}


/*CHANGE TEXT COLOR WHEN HOVER*/
.text-shadow-white:hover {
  text-shadow: 0.5px 0px white;
}

.text-shadow-blue:hover{
  text-shadow: 0.2px 0px var(--royal-blue) !important;
}

.text-hover-royal-blue:hover a {
  color: var(--royal-blue) !important;
}

.text-hover-blue:hover {
  color: var(--royal-blue) !important;
}

.text-hover-blue-2:hover {
  color: var(--royal-blue-light) !important;
}

.text-hover-gold:hover {
  color: var(--for-gold-light) !important;
}

.text-hover-gray:hover {
  text-shadow: 0px 0.2px var(--gray-strong) !important;
}

.text-hover-gold-2:hover {
  color: var(--gold-opacity) !important;
}

.text-hover-blue-shadow:hover {
  text-shadow: 0.3px 0px var(--royal-blue) !important;
}

.text-hover-white:hover{
  color: #F7F7F7;
}

/*END TEXT COLORS*/





/*START BACKGROUND COLORS*/
.background-gold {
  background-color: var(--for-gold-light) !important;
}

.background-gray {
  background-color: var(--gray-light) !important;
}

.background-red {
  background-color: #E6222E !important;
}

.background-royal-blue {
  background-color: var(--royal-blue) !important;
}

.background-royal-blue-2 {
  background-color: var(--royal-blue-2);
}

.background-sections {
  background-color: var(--white-f7) !important;
}

.background-white {
  background-color: var(--white-f7) !important;
}

.background-royal-blue-light {
  background-color: var(--royal-blue-light) !important;
}

.botonFormularioDepositante {
  background-color: var(--royal-blue) !important;
  color: var(--white-f7) !important;
}

.botonFormularioDepositanteRechazar {
  background-color: #E6222E !important;
  color: var(--white-f7) !important;
}

.botonFormularioDepositanteAprobar {
  background-color: var(--for-gold-light) !important;
  color: var(--royal-blue) !important;
}

/*   BACKGROUND WHEN HOVER  */
.bg-hover-gray-light:hover {
  background-color: var(--gray-light) !important;
}

.botonFormularioDepositante:hover {
  color: var(--royal-blue) !important;
  background-color: var(--white-f7) !important;

}

.botonFormularioDepositanteRechazar:hover {
  color: #E6222E !important;
  background-color: var(--white-f7) !important;
}


.botonFormularioDepositanteAprobar:hover {
  color: var(--for-gold-light) !important;
  background-color: var(--white-f7) !important;
}

.bg-hover-gold:hover {
  background-color: var(--for-gold-light) !important;
}

.bg-hover-blue-royal:hover {
  background-color: var(--royal-blue-light) !important;
  color: var(--gray-light) !important;
}

.bg-hover-white:hover {
  background-color: var(--white-f7) !important;
  color: var(--royal-blue) !important;
}

.bg-hover-red:hover {
  background-color: rgb(185, 8, 8) !important;
  color: var(--white-f7) !important;
}

.bg-hover-gold-light:hover{
  background-color: var(--gold-opacity) !important;
}

/*END CHANGE BACKGROUND COLOR WHEN HOVER*/

/*END BACKGROUND COLORS*/




/*Altura para texto de las tarjetas de menu registro de valores físicos*/
.height-150px {
  height: 150px !important;
}

/*Margen para contenedores en las pantallas de login*/
.mt-8rem {
  margin-top: 9rem !important;
}

.margin-bottom {
  margin-bottom: 450px !important;
}

.table-container {
  min-height: 480px !important;
}

.notFound-container {
  min-height: 650px !important;
}

.margin-0px {
  margin: 0 !important;
}

.margin-container {
  margin-top: 7.5rem !important;
}

.margin-container-DepositanteDirecto {
  margin-top: 10rem !important;
}

.margin-200 {
  margin-top: 12.5rem !important;
}

.margin-top-100 {
  margin-top: 6.30rem !important;
}

.padding-e-9rem {
  padding-right: 9rem !important;
}



/* START FOOTER STYLES*/
.logoFooter {
 /* width: 15rem;
  height: 5rem;*/
}

.logoFooterCehval {
  width: 7rem;
  height:7rem;
}

.textoFooter {
  color: #F7F7F7;
  font-size: .7rem;
}

.tituloFooter {
  color: var(--for-gold-light);
  font-size: .8rem;
}

#footer {
  background-color: gray;
  position: absolute;
  bottom: 0;
  /*position:fixed;*/
  width: 100%;
  height: 20%;
}



.padding-x-footer {
  padding: 0px 80px !important;
}

#logo {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-top: 30px;
}

/* END FOOTER STYLES*/



/* START STYLES LOGIN*/
#loginImageContainer {
  width: 56.25rem !important;
  height: 60rem !important;
}


/*Button to show password*/
.button-show-password {
  background-color: white !important;
  border: none;
  box-shadow: none;
}

/* END STYLES LOGIN*/


/*Component filtroTabla*/
.input-wrapper {
  position: relative;
  width: 271px;
}


/* START TABLE STYLES*/
table {
  border-spacing: 0;
  border: 1px solid #d1cccc;
}

tr:last-child td {
  border-bottom: 0;
}

th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid #d1cccc;
  border-right: 1px solid #d1cccc;
}

th:last-child,
td:last-child {
  border-right: 0;
}

tr:nth-child(odd) {
  background: #f3f3f3;
  color: #000000;
}

tr:nth-child(even) {
  background: #ffffff;
  color: #000000;
}

th.desc {
  border-bottom: 5px solid rgb(136, 136, 255);
}

th.asc {
  border-top: 5px solid orange;
}

.text-score-registers{
  font-size: 1.2rem;
}

@media (max-width: 668px) {
  .text-score-registers {
    font-size: 1rem !important;
  }
}

@media (max-width: 466px) {
  .text-score-registers {
    font-size: 0.8rem !important;
  }
}



.text-pagination {
  font-size: 18px;
}
.page-height{
  height: 38px !important;
  padding-top: 9px !important;
}

@media (max-width: 767px) {
  .text-pagination {
    font-size: 12px !important;
  }

  .page-width{
    width: 110px !important;
  }

  .page-height{
    height: 38px !important;
    padding-top: 10px !important;
  }
}



/* END TABLE STYLES*/



#divRedesSocialesEncabezado {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
}

.divRedesSocialesFooter {
  font-size: 15px;
}

.imagenesRedesSocialesEncabezado {
  margin-bottom: 20px;
  color: white;
}

#encabezado {
  padding: 10px;
  background-color: var(--principal-royal-blue);
}

#encabezado1 {
  padding: 30px;
  background-color: #253f6d;
}

#encabezado2 {
  padding: 30px;
  background-color: rgb(0, 51, 255);
}


.vista {
  background-color: #25696D;
  border-radius: "50%";
  width: 50%;
  font-weight: bold;
  color: white;
  padding-top: 1%;
  padding-bottom: 1%;
}

.containerModal {
  flex: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;

}

.error {
  color: red
}

.espacioMargin {
  margin-bottom: 10px;
}

/* HIDE FORM TO REGISTER SERIES*/
#formRegisterSeries {
  display: none;
}



/*ESTILOS FORMULARIO REGISTRO SERIES*/
.text-uppercase {
  text-transform: uppercase !important;
}


/*ESTILOS PARA LAS PANTALLAS DE INICIO*/


.padding-text-left {
  padding-left: 13px !important;
}


/*START STYLES BUBBLE LOADING SCREEN*/
.loader:before,
.loader:after,
.loader {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation: bubbleLoading 1.8s infinite ease-in-out;
  -webkit-animation: bubbleLoading 1.8s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.loader {
  margin: 8em auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}

.loader:before {
  left: -3.5em;
}

.loader:after {
  left: 3.5em;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

@-webkit-keyframes bubbleLoading {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em var(--for-gold-light);
  }

  40% {
    box-shadow: 0 2.5em 0 0 var(--for-gold-light);
  }
}

@keyframes bubbleLoading {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em var(--for-gold-light);
  }

  40% {
    box-shadow: 0 2.5em 0 0 var(--for-gold-light);
  }
}

.modalLoading {
  opacity: .7;
}

/*START STYLES BUBBLE LOADING SCREEN*/


/*START STYLES FOR PAGE NOT FOUND*/
#notFoundImage {
  width: 250px;
  height: 70px;
}

#background-not-found {
  height: 40rem;
  background-color: var(--royal-blue-light);
}

#background-not-found h3 {
  font-size: 30px !important;
}

/*END STYLES FOR PAGE NOT FOUND*/







#sidebar {
  width: 100% !important;
  height: 100%;
  /*height: 100vh;*/
  background: #242848;
  border-right: 3px solid var(--for-gold-light);
  /*
  overflow-x: visible;
  overflow-y: scroll;
  */
}


/*►›*/
.icon-drop-down::after {
  content: " ›";
}

.icon-dropdown-options::before {
  content: "- ";
}

.paddingY {
  padding: 14px 0px !important;
}

/*
.inputs-monto-objetivo{
  border: none !important;
  outline: none !important;
  background-color: #fff !important;
}*/


/*INICIO ESTILOS REGISTRO EMISORES BCV*/
.border-dashed {
  border: 2px dashed var(--gray-medium) !important;
}


#fileDropArea {
  min-height: 500px;
}

#fileDropAreaISIN {
  min-height: 200px;
}

@media (max-width: 668px) {
  #fileDropArea {
    min-height: 300px;
  }
}


.active {
  background-color: var(--for-gold-light);
  border: 2px dashed var(--royal-blue) !important;
}

#text-click-uploadfile:hover {
  cursor: pointer;
  text-decoration: underline;
}

/*FIN ESTILOS REGISTRO EMISORES BCV*/

/*Clase para definir altura de textarea*/
.rejectionReason {
  min-height: 9.3rem !important;
  max-height: 17.5rem !important;
}


/*Oculta la imagen del login*/
@media (max-width: 991px) {
  #loginImageContainer {
    display: none;
  }
}

/*Oculta navbar y muestra sidenavbar */
@media (max-width: 1024px) {
  #navbar {
    display: none;
  }

  #inicioSesionHeader {
    display: none !important;
  }

  #btnSideNavbarContainer {
    display: flex;
  }
}


@media (max-width: 535px) {
  #bannerTexto {
    width: 80% !important;
  }
}

@media (max-width: 767px) {
  .marginButtonAprove {
    margin-bottom: 10px;
  }
}

/*Fin ajustes banner solicitud de registro*/

/*Font size*/
@media (max-width: 992px) {
  .fs-small {
    font-size: 15px !important;
  }

  #cehval-menu {
    top: 116px;
  }
}

@media (max-width: 366px) {
  .fs-small {
    font-size: 12px !important;
  }
}

@media (max-width: 323px) {
  .fs-small {
    font-size: 10px !important;
  }
}


/*Hide "Acciones Cehval" in header at 767px*/
@media (max-width: 767px) {
  #acciones-cehval {
    display: none !important;
  }
}


/*START RESPONSIVE FOR SUBMENU*/
@media (max-width: 1157px) {
  .submenu-dropdown {
    width: 740px !important;
  }
}

@media (max-width: 1096px) {
  .submenu-dropdown {
    width: 680px !important;
  }
}

@media (max-width: 1024px) {
  .submenu-dropdown {
    width: 600px !important;
  }
}

@media (max-width: 992px) {
  .submenu-dropdown {
    width: 500px !important;
  }
}

@media (max-width: 890px) {
  .submenu-dropdown {
    width: 415px !important;
    height: 530px !important;
  }
}

/*END RESPONSIVE FOR SUBMENU*/